<template>
  <div class="member-index d-flex justify-center">
    <div class="card-wrap w-full">
      <pageTitle icon="icon-member-red" text="memberCenter"></pageTitle>

      <div v-for="(item, i) in Object.keys(info)" :key="i" class="mb-2">
        <div class="red--text rem-13">{{ $t(item) }}</div>
        <div class="secondary--text rem-13">
          {{ info[item] }}
          <span v-if="item==='BSCaddress'" class="red--text text-decoration-underline ml-3 can-click" @click="resetAddressShow=true">{{ $t('edit') }}</span>
        </div>
      </div>
    </div>

    <dialogs :title="dialogTitle" :content="'enterAgain'"></dialogs>
    <!-- 重設 bsc address -->
    <dialogs :resetShow.sync="resetAddressShow" isCustom>
      <template slot="custom" class="w-full">
        <v-form ref="resetForm" lazy-validation>
          <inputBlock class="mb-2" title="phone" :inputText.sync="phone" column :rules="[required, ...phoneRules]"></inputBlock>
          <inputBlock class="mb-2" title="validateCode" :inputText.sync="validateCode" column isSend @send="sendValidateCode(phone, 5)"></inputBlock>
          <inputBlock class="mb-2" title="BSCaddress" :inputText.sync="newBSCaddress" column :rules="[required, ...accountRules]"></inputBlock>
        </v-form>
        <btn isCenter :buttonText="'confirm'" @clickBtn="resetBscAddress()"></btn>
      </template>
    </dialogs>
  </div>
</template>
<script>
import base from '@/mixin/base'
import pageTitle from '@/components/pageTitle.vue'
import dialogs from '@/components/dialogs.vue'
import inputBlock from '@/components/inputBlock.vue'
import btn from '@/components/btn.vue'
export default {
  name: "Member",
  mixins: [base],
  data(){
    return {
      info: {},
      resetAddressShow: false,
      phone: '',
      validateCode: '',
      newBSCaddress: '',
      dialogTitle: '',
    }
  },
  watch:{
    "$store.state.nowWidth": function(newVal){
      this.info.BSCaddress = newVal > 600 ? this.$store.state.userInfo.address : this.shortAddress
    },
    resetAddressShow(newVal){
      if (!newVal){
        this.$refs.resetForm.reset()
      }
    }
  },
  components:{
    pageTitle,
    dialogs,
    inputBlock,
    btn
  },
  methods:{
    async resetBscAddress(){
      if (this.$refs.resetForm.validate()){
        let data = {
          address: this.newBSCaddress,
          phone_number: this.phone.slice(1),
          phone_country_code: this.phone[0],
          phone_verify_code: this.validateCode
        }
        let result = await this.$store.dispatch('resetBscAddress', data)
        console.log('result', result)
        if (result.status === 683){
          this.$toasted.show(this.$t('resetBscAddressSuccess'))
          await this.$store.dispatch('getUserInfo')
          this.info.BSCaddress = this.$store.state.nowWidth > 600 ? this.$store.state.userInfo.address : this.shortAddress
          this.$refs.resetForm.reset()
          this.resetAddressShow = false
        }else if (result.status === 732){
          this.dialogTitle = 'codeHasExpired'
          this.$store.commit('updateDialogShow', true)
        }else if (result.status === 733){
          this.dialogTitle = 'codeWrong'
          this.$store.commit('updateDialogShow', true)
        }else if (result.status === 675){
          this.dialogTitle = 'accountNotExist'
          this.$store.commit('updateDialogShow', true)
        }else{
          this.dialogTitle = 'resetBscAddressFail'
          this.$store.commit('updateDialogShow', true)
        }
      }
    }
  },
  mounted(){
    let info = this.$store.state.userInfo
    this.info = {
      referer: `0${info.referrer_phone}`,
      account: `0${info.phone_number}`,
      name: info.name,
      idNumber: info.id_card,
      gender: this.$t(info.gender),
      birth: info.birthday,
      BSCaddress: this.$store.state.nowWidth > 600 ? info.address : this.shortAddress,
      email: info.email,
      residentialAddress: info.live_address,
    }
  }
}
</script>